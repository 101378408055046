import React from "react"
import Header4 from "./../Common/Header4"
import Footer from "./../Common/Footer"
import Banner from "./../Elements/Banner"
import GoogleMapReact from "google-map-react"

var bnrimg = require("./../../images/banner/3.jpg")
var bgimg1 = require("./../../images/background/bg6.jpg")

const AnyReactComponent = ({ text }) => <div>{text}</div>

class News1 extends React.Component {
  render() {
    const defaultProps = {
      center: {
        lat: 34.07328,
        lng: -118.25141,
      },
      zoom: 12,
    }
    return (
      <>
        <Header4 />
        <div className="page-content">
          {/* <Banner title="Contact Us Form" pagename="Contact us" description="The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized." bgimage={bnrimg}/> */}
          <Banner
            title="News"
            pagename="News"
            description=" بەم زووانە "
            bgimage={bnrimg}
          />

          {/* SECTION CONTENTG START */}
          <div className="section-full mobile-page-padding p-t80 p-b80 bg-gray">
            <div className="container">
              <div className="section-content">
                <div className="row">
                  <div className="col-xl-5 col-lg-5 col-md-12 ">
                    <div
                      className="home-2-about bg-bottom-left bg-no-repeat bg-cover"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    ></div>
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-12">
                    <div className="about-home-2">
                      <h3 className="m-t0 sx-tilte">
                        ئێمە ئاگادارتان دەکەینەوە کاتێک بەرهەمی نوێ دەگاتە ئێرە
                      </h3>
                      <p>
                        {/* We take pride in offering top-tier lighting solutions,
                        combining innovation, quality, and value to meet the
                        diverse needs of our customers. Our competitive edge
                        ensures that we deliver the best lighting options on the
                        market. */}
                      </p>
                      <div className="text-left">
                        {/* <NavLink to={"/about-2"} className="site-button-link">
                        Read More
                      </NavLink> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>

        <Footer />
      </>
    )
  }
}

export default News1
