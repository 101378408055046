import React from "react"
import { NavLink } from "react-router-dom"

var bgimg1 = require("./../../images/background/bg5.jpg")

class About2 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-t80 p-b80 bg-gray">
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-xl-5 col-lg-5 col-md-12 ">
                  <div
                    className="home-2-about bg-bottom-left bg-no-repeat bg-cover"
                    style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                  ></div>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-12">
                  <div className="about-home-2">
                    <h3 className="m-t0 sx-tilte">
                      Offering the best lights at unbeatable prices!TEST2
                    </h3>
                    <p>
                      We take pride in offering top-tier lighting solutions,
                      combining innovation, quality, and value to meet the
                      diverse needs of our customers. Our competitive edge
                      ensures that we deliver the best lighting options on the
                      market.
                    </p>
                    <div className="text-left">
                      {/* <NavLink to={"/about-2"} className="site-button-link">
                        Read More
                      </NavLink> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default About2
