import React from "react"
import { NavLink } from "react-router-dom"

const filters = [
  { label: "Bathroom", filter: ".cat-1" },
  { label: "Decore", filter: ".cat-2" },
  { label: "Kitchen", filter: ".cat-3" },
  { label: "Living room", filter: ".cat-4" },
  //   { label: "Residential", filter: ".cat-5" },
]

const projects = [
  {
    image: require("./../../images/projects/portrait/pic1.jpg"),
    title: "Modern Bath",
    address: "White and Bright",
    filter: "cat-1",
  },
  {
    image: require("./../../images/projects/portrait/pic3.jpg"),
    title: "Outdoor",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/portrait/pic6.jpg"),
    title: "White Light",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/portrait/pic9.jpg"),
    title: "Vertical Light",
    address: "",
    filter: "cat-4",
  },
  {
    image: require("./../../images/projects/portrait/pic8.jpg"),
    title: "Light Light",
    address: "",
    filter: "cat-5",
  },
  {
    image: require("./../../images/projects/portrait/pic5.jpg"),
    title: "Living Room Light",
    address: "",
    filter: "cat-4",
  },
  {
    image: require("./../../images/projects/portrait/pic7.jpg"),
    title: "Yellow Light",
    address: "",
    filter: "cat-3",
  },
  {
    image: require("./../../images/projects/portrait/pic4.jpg"),
    title: "Indoor",
    address: "",
    filter: "cat-2",
  },
  {
    image: require("./../../images/projects/portrait/pic2.jpg"),
    title: "Modern Bath",
    address: "Darker",
    filter: "cat-1",
  },
]

var bgimg1 = require("./../../images/background/cross-line2.png")
var bgimg2 = require("./../../images/background/cross-line.png")

class Projects2 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script")
        script.src = src
        script.addEventListener("load", function () {
          resolve()
        })
        script.addEventListener("error", function (e) {
          reject(e)
        })
        document.body.appendChild(script)
        document.body.removeChild(script)
      })
    }

    loadScript("./assets/js/custom.js")
  }
  render() {
    return (
      <>
        <div className="section-full  mobile-page-padding bg-white  p-t80 p-b80">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div
                  className="sx-separator bg-white bg-moving bg-repeat-x"
                  style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                >
                  <h3 className="sep-line-one">Categories</h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            {/* Filter Nav START */}
            <div className="filter-wrap p-b30 text-center">
              <ul className="filter-navigation masonry-filter clearfix">
                <li className="active">
                  <NavLink
                    to={"#"}
                    className="btn from-top"
                    data-filter="*"
                    data-hover="All"
                  >
                    All
                  </NavLink>
                </li>
                {filters.map((item, index) => (
                  <li key={index}>
                    <NavLink
                      to={"#"}
                      className="btn from-top"
                      data-filter={item.filter}
                    >
                      {item.label}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
            {/* Filter Nav END */}
            {/* GALLERY CONTENT START */}
            <ul className="masonry-outer mfp-gallery work-grid row clearfix list-unstyled m-b0">
              {projects.map((item, index) => (
                <div
                  key={index}
                  className={`${item.filter} masonry-item col-lg-4 col-md-6 col-sm-12 m-b30`}
                >
                  <div className="sx-box image-hover-block">
                    <div className="sx-thum-bx">
                      <img src={item.image} alt="" />
                    </div>
                    <div className="sx-info  p-t20 text-white">
                      <h4 className="sx-tilte">
                        <span >{item.title}</span>
                      </h4>
                      <p className="m-b0">{item.address}</p>
                    </div>
                    <a className="mfp-link" href={item.image}>
                      <i className="fa fa-arrows-alt" />
                    </a>
                  </div>
                </div>
              ))}
            </ul>
            {/* GALLERY CONTENT END */}
            <div
              className="text-center load-more-btn-outer"
              style={{ backgroundImage: "url(" + bgimg2 + ")" }}
            >
              {/* <button className="site-button-secondry btn-half">
                <span>Load More</span>
              </button> */}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Projects2
