import React from "react"
import Header4 from "./../Common/Header4"
import Footer from "./../Common/Footer"
import Banner from "./../Elements/Banner"
import About2 from "./../Elements/About2"
import WhatWeDo1 from "../Elements/WhatWeDo1"

// var bnrimg = require("./../../images/banner/5.jpg")

class About1 extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script")
        script.src = src
        script.addEventListener("load", function () {
          resolve()
        })
        script.addEventListener("error", function (e) {
          reject(e)
        })
        document.body.appendChild(script)
        document.body.removeChild(script)
      })
    }

    loadScript("./assets/js/custom.js")
  }
  render() {
    return (
      <>
        <Header4 />
        <div className="page-content">
          <Banner
            title="About Company"
            // pagename="About 1"
            description="پێشکەشکردنی باشترین رووناکی بە نرخێکی بێ بەزاندن!
            ئێمە شانازی دەکەین بە پێشکەشکردنی چارەسەری رووناکی ئاست بەرز، تێکەڵکردنی داهێنان، کوالێتی، و بەها بۆ دابینکردنی پێداویستییە جۆراوجۆرەکانی کڕیارەکانمان. بەرژەوەندی پێشبڕکێی ئێمە دڵنیایی دەدات کە باشترین بژاردەی رووناکی لە بازاڕدا پێشکەش دەکەین."
            // bgimage={bnrimg}
          />
          <About2 />
          <WhatWeDo1 />
        </div>

        <Footer />
      </>
    )
  }
}

export default About1
