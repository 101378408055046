import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import Home2 from "./Pages/Home2"

import About1 from "./Pages/About1"
import About2 from "./Pages/About2"

import ContactUs from "./Pages/ContactUs"
import ScrollToTop from "./Common/ScrollToTop"
import Footer from "./Common/Footer"

import Testimonials2 from "./Elements/Testimonials2"
import News1 from "./Pages/News1"

class Components extends React.Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <ScrollToTop />
        <div className="page-wraper">
          <Routes>
            <Route path="/" element={<Home2 />} />

            <Route path="/about-1" element={<About1 />} />
            <Route path="/about-2" element={<About2 />} />

            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/footer" element={<Footer />} />
            <Route path="/news1" element={<News1 />} />
          </Routes>
        </div>
      </BrowserRouter>
    )
  }
}

export default Components
